//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { imgChange, numChange } from "@/commons";
export default {
  components: {
    XModal: () => import("@/components/XModal"),
    CWinTmp: () => import("@/components/CWinTmp"),
    mb: () => import("@/components/Selectors/MemberSelectCopy"),
  },
  props: {
    selUser: {
      type: Object,
      default: null,
    },
    teamId: {
      type: Number,
      default: null,
    },
    userMemberMType: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      options: [],
      editState: false,
      indexData: {
        type: "",
        name: "成员详情",
        xModalName: "userInfo",
      },
      formData: {
        Id: null,
        visible: [],
        mType: null,
        progroup: null,
        addressArray: [],
        IsAccept: true,
        AcceptMember: [],
        NikeName: null,
      },
      AuditorByMember: [], //审核人
      VisiblesByMember: [],
    };
  },
  filters: {
    getShape(val) {
      if (val == 1) {
        return "正常";
      } else if (val == 0) {
        return "禁用";
      } else {
        return "删除";
      }
    },
  },
  methods: {
    imgChange,
    numChange,
    /**
     * 获取审核人
     */
    getAuditor(val) {
      this.AuditorByMember = val;
    },
    /**
     * 获取审核人
     */
    getVisiblesByMember(val) {
      this.VisiblesByMember = val;
    },
    /**
     * 删除某一个邮箱
     */
    delAddress(ind) {
      this.formData.addressArray = this.formData.addressArray.filter(
        (m, index) => ind != index
      );
    },
    /**
     * 添加预警接收的邮箱
     */
    addAddress() {
      this.formData.addressArray.push({
        inputVal: null,
      });
    },
    // 获取进程组
    getDataList() {
      let params = {
        teamId: this.teamId,
      };
      this.$http
        .post(
          "/Management/ProgressManagement/GetProgressGroupMeberSelected.ashx",
          params
        )
        .then((result) => {
          if (result.res == 0) {
            this.options = result.data;
          }
        });
    },
    /**
     * 编辑
     */
    comSubmit() {
      this.$refs.cwtinfo.toggleComfirm();
      const address = this.formData.addressArray.filter((m) => m.inputVal);
      const data = {
        Id: this.formData.id,
        visibleUsId: this.formData.visible.map((m) => m.UsId),
        mType: this.formData.mType,
        progressgroupId: this.formData.progroup,
        IsAccept: this.formData.IsAccept,
        NikeName: this.formData.NikeName,
        AcceptMembers: this.formData.AcceptMember.map((m) => m.UsId),
        AcceptEmails: address.map((m) => m.inputVal),
        AuditorByMember: this.AuditorByMember.map((m) => m.UsId),
        VisiblesByMember: this.VisiblesByMember.map((m) => m.UsId),
      };
      this.$http.post("/Teams/EditMember.ashx", data).then((resp) => {
        if (resp.res == 0) {
          this.$message({
            type: "success",
            message: "编辑成功!",
          });
          this.$modal.hide("userInfo");
          this.$emit("success");
        }
      });
    },
    closed() {
      Object.keys(this.formData).forEach((m) => {
        if (["Id", "mType", "progroup", "NikeName"].includes(m)) {
          this.formData[m] = null;
        } else if (["visible", "addressArray", "AcceptMember"].includes(m)) {
          this.formData[m] = [];
        } else if (["IsAccept"].includes(m)) {
          this.formData[m] = true;
        }
      });
      this.AuditorByMember = [];
      this.VisiblesByMember = [];
    },
    /**
     * 弹窗打开回调
     */
    opened() {
      this.$nextTick(() => {
        if (this.userMemberMType != 1) {
          this.indexData.type = "Edit";
        } else {
          this.indexData.type = "";
        }
      });
      this.editState = false;
      this.getDataList();
    },
    editClick() {
      if (this.editState === true) this.changeEditState();
    },
    // 改变窗口状态的，搭配index页 添加和编辑按钮
    changeEditState() {
      if (this.selUser) {
        this.formData.id = this.selUser.Id;
        if (this.selUser.VisibleUser) {
          this.formData.visible = this.selUser.VisibleUser.map((m) => {
            return {
              UsId: m.UserId,
              Name: m.Name,
              Picture: m.Picture,
            };
          });
        } else {
          this.formData.visible = [];
        }
        if (this.selUser.AuditorByMember) {
          this.AuditorByMember = this.selUser.AuditorByMember.map((m) => {
            return {
              UsId: m.UserId,
              Name: m.Name,
              Picture: m.Picture,
            };
          });
        } else {
          this.AuditorByMember = [];
        }
        if (this.selUser.VisiblesByMember) {
          this.VisiblesByMember = this.selUser.VisiblesByMember.map((m) => {
            return {
              UsId: m.UserId,
              Name: m.Name,
              Picture: m.Picture,
            };
          });
        } else {
          this.VisiblesByMember = [];
        }
        this.formData.mType = this.selUser.MType;
        this.formData.progroup = this.selUser.ProgressGroupName;
        this.formData.IsAccept = this.selUser.IsAccept;
        this.formData.NikeName = this.selUser.NikeName;
        if (this.selUser.AcceptEmail && this.selUser.AcceptEmail.length) {
          this.formData.addressArray = this.selUser.AcceptEmail.map((m) => {
            return {
              inputVal: m,
            };
          });
        }
        if (this.selUser.AcceptMember && this.selUser.AcceptMember.length) {
          this.formData.AcceptMember = this.selUser.AcceptMember.map((m) => {
            return {
              UsId: m.UserId,
              Name: m.Name,
              Picture: m.Picture,
            };
          });
        }
      }
    },
    /**
     * 接收可见成员
     */
    getUser(val) {
      this.formData.visible = val;
    },
    /**
     * 接收接收每日日报的成员
     */
    getAcceptUser(val) {
      this.formData.AcceptMember = val;
    },
  },
};
